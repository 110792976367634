import './Galery.css'
import React from 'react'

const Galery = () => {
  return (
    <section className='galeryContainer'>
      <div id='Informacion' className='generalTextContainer'>
      </div>
    </section>
  )
}

export default Galery