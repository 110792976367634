import './Informacion.css'
import React from 'react'

const Informacion = () => {
  return (
    <section className='infoContainer'>
      <div id='Informacion' className='generalTextContainer'>
          <div className='titleContainer'>
              <p className='p1'> <span className='p1Left'>*01* | </span> INFORMACION</p>
          </div>
          <div className='descriptionContainer'>
              <p>Bienvenido a Ecoming, tus aliados de marketing digital! En Ecoming buscamos impulsar el crecimiento de tu negocio. Nuestro equipo, expertos en marketing, te van ayudar a navegar por el apasionante mundo digital y descubrir estrategias innovadoras para aumentar tu visibilidad en linea y fortalecer tu marca. Con un enfoque centrado en el cliente y soluciones personalizadas trabajaremos juntos para alcanzar tus objetivos.
                ¿Estás listo? Abrocha tu cinturón y prepárate para potenciar tu marca dejando una huella en el mercado🚀<br/><br/>
                🤝¡En Ecoming tu éxito es nuestro éxito!🤝</p>
          </div>
      </div>
    </section>
  )
}

export default Informacion